@import "styles/vendors/bootstrap";

* {
    margin: 0;
    padding: 0;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    min-height: 100%;
}

html {
    font-size: 16px;
}

body {
    margin: 0;
    font-family: Lato, sans-serif;
}

:root {
    --color-primary: #0053d4;
    --color-secondary: #d9eefd;
    --color-disabled: #d6dcE4;

    --text-primary: #0d3c61;
    --text-secondary: #0053d4;
    --text-disabled: #636768;
    --text-black: #353535;

    --bs-blue: #286ea9;
    --bs-blue-2: #00409d;
    --bs-blue-green: #002f4d;
    --bs-grey: #f5f6f8;
    --bs-black: #333333;

    --border-error: #e25967;
    --bg-error: #fff5f5;
}

:root .mx-0 {
    margin-right: 0rem;
    margin-left: 0rem;
}

:root .my-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
}

:root .mx-1 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
}

:root .my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

:root .mx-2 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}

:root .my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

:root .mx-3 {
    margin-right: 0.75rem;
    margin-left: 0.75rem;
}

:root .my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}

:root .mx-4 {
    margin-right: 1rem;
    margin-left: 1rem;
}

:root .my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

:root .px-0 {
    padding-right: 0rem;
    padding-left: 0rem;
}

:root .py-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
}

:root .px-1 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
}

:root .py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

:root .px-2 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

:root .py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

:root .px-3 {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

:root .py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

:root .px-4 {
    padding-right: 1rem;
    padding-left: 1rem;
}

:root .py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

:root .mt-0 {
    margin-top: 0 !important;
}

:root .mr-0 {
    margin-right: 0;
}

:root .mb-0 {
    margin-bottom: 0;
}

:root .ml-0 {
    margin-left: 0;
}

:root .mt-1 {
    margin-top: 0.25rem;
}

:root .mr-1 {
    margin-right: 0.25rem;
}

:root .mb-1 {
    margin-bottom: 0.25rem;
}

:root .ml-1 {
    margin-left: 0.25rem;
}

:root .mt-2 {
    margin-top: 0.5rem;
}

:root .mr-2 {
    margin-right: 0.5rem;
}

:root .mb-2 {
    margin-bottom: 0.5rem;
}

:root .ml-2 {
    margin-left: 0.5rem;
}

:root .mt-3 {
    margin-top: 0.75rem;
}

:root .mr-3 {
    margin-right: 0.75rem;
}

:root .mb-3 {
    margin-bottom: 0.75rem;
}

:root .mk-3 {
    margin-left: 0.75rem;
}

:root .mt-4 {
    margin-top: 1rem;
}

:root .mr-4 {
    margin-right: 1rem;
}

:root .mb-4 {
    margin-bottom: 1rem;
}

:root .ml-4 {
    margin-left: 1rem;
}

:root .mt-5 {
    margin-top: 1.25rem;
}

:root .mr-5 {
    margin-right: 1.25rem;
}

:root .mb-5 {
    margin-bottom: 1.25rem;
}

:root .ml-5 {
    margin-left: 1.25rem;
}

:root .mt-6 {
    margin-top: 1.5rem;
}

:root .mr-6 {
    margin-right: 1.5rem;
}

:root .mb-6 {
    margin-bottom: 1.5rem;
}

:root .ml-6 {
    margin-left: 1.5rem;
}

:root .mt-8 {
    margin-top: 2rem;
}

:root .mr-8 {
    margin-right: 2rem;
}

:root .mb-8 {
    margin-bottom: 2rem;
}

:root .ml-8 {
    margin-left: 2rem;
}

:root .mt-9 {
    margin-top: 2.25rem;
}

:root .mr-9 {
    margin-right: 2.25rem;
}

:root .mb-9 {
    margin-bottom: 2.25rem;
}

:root .ml-9 {
    margin-left: 2.25rem;
}

:root .mt-10 {
    margin-top: 2.5rem;
}

:root .mr-10 {
    margin-right: 2.5rem;
}

:root .mb-10 {
    margin-bottom: 2.5rem;
}

:root .ml-10 {
    margin-left: 2.5rem;
}

:root .pt-0 {
    padding-top: 0;
}

:root .pr-0 {
    padding-right: 0;
}

:root .pb-0 {
    padding-bottom: 0;
}

:root .pl-0 {
    padding-left: 0;
}

:root .pt-1 {
    padding-top: 0.25rem;
}

:root .pr-1 {
    padding-right: 0.25rem;
}

:root .pb-1 {
    padding-bottom: 0.25rem;
}

:root .pl-1 {
    padding-left: 0.25rem;
}

:root .pt-2 {
    padding-top: 0.5rem;
}

:root .pr-2 {
    padding-right: 0.5rem;
}

:root .pb-2 {
    padding-bottom: 0.5rem;
}

:root .pl-2 {
    padding-left: 0.5rem;
}

:root .pt-3 {
    padding-top: 0.75rem;
}

:root .pr-3 {
    padding-right: 0.75rem;
}

:root .pb-3 {
    padding-bottom: 0.75rem;
}

:root .pl-3 {
    padding-left: 0.75rem;
}

:root .pt-4 {
    padding-top: 1rem;
}

:root .pr-4 {
    padding-right: 1rem;
}

:root .pb-4 {
    padding-bottom: 1rem;
}

:root .pl-4 {
    padding-left: 1rem;
}

:root .pt-5 {
    padding-top: 1.25rem;
}

:root .pr-5 {
    padding-right: 1.25rem;
}

:root .pb-5 {
    padding-bottom: 1.25rem;
}

:root .pl-5 {
    padding-left: 1.25rem;
}

:root .pt-6 {
    padding-top: 1.5rem;
}

:root .pr-6 {
    padding-right: 1.5rem;
}

:root .pb-6 {
    padding-bottom: 1.5rem;
}

:root .pl-6 {
    padding-left: 1.5rem;
}

:root .pt-8 {
    padding-top: 2rem;
}

:root .pr-8 {
    padding-right: 2rem;
}

:root .pb-8 {
    padding-bottom: 2rem;
}

:root .pl-8 {
    padding-left: 2rem;
}

:root .pt-9 {
    padding-top: 2.25rem;
}

:root .pr-9 {
    padding-right: 2.25rem;
}

:root .pb-9 {
    padding-bottom: 2.25rem;
}

:root .pl-9 {
    padding-left: 2.25rem;
}

:root .pt-10 {
    padding-top: 2.5rem;
}

:root .pr-10 {
    padding-right: 2.5rem;
}

:root .pb-10 {
    padding-bottom: 2.5rem;
}

:root .pl-10 {
    padding-left: 2.5rem;
}

:root .fw-500 {
    font-weight: 500 !important;
}

:root .fw-600 {
    font-weight: 600 !important;
}

:root .fw-700 {
    font-weight: 700 !important;
}

:root .w-100 {
    width: 100%;
}

label,
input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: transparent;
}

a {
    text-decoration: none;
}

input[type=radio],
input[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type=radio],
input[type=checkbox] {
    width: 1rem;
    height: 1rem;
    border: 1px solid var(--text-primary);
    background: #fff;
    position: relative;
    outline: none;
    cursor: pointer;
}

input[type=radio]:checked,
input[type=checkbox]:checked {
    border: 1px solid var(--color-primary);
    background: var(--color-primary);
}

input[type=radio]:checked::before,
input[type=checkbox]:checked::before {
    content: "";
    width: 0.4rem;
    height: 0.4rem;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

input[type=radio],
input[type=radio]:checked::before {
    border-radius: 100%;
}

.btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 12px;
    padding: 14px;
    border-radius: 10px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 20px;
    cursor: pointer;
    outline: none;

    @include media-breakpoint-up(sm) {
        min-width: 220px;
    }
}

.btn-primary {
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    color: #fff;
}

.btn-primary:disabled {
    background-color: var(--color-disabled);
    border: 1px solid var(--color-disabled);
    color: #fff;
    pointer-events: none;
}

.btn-outline-primary {
    background-color: #fff;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
}

.btn-outline-primary:disabled {
    pointer-events: none;
}

.btn-cd {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.375rem 0.75rem;
    border-radius: 5px;
    font-family: 'Archivo', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.002em;
    color: #fff;
    outline: none;
    user-select: none;

    @include media-breakpoint-up(sm) {
        min-width: 220px;
    }
}

.btn-cd:not(:disabled) {
    cursor: pointer;
}


.btn-cd-primary {
    background-color: #F93449;
    border: 1px solid #F93449;
    color: #fff;
}

.btn-cd-primary:disabled {
    background-color: #FEDBD7;
    border: 1px solid #FEDBD7;
    color: #fff;
    pointer-events: none;
}

.btn-cd-outline-primary {
    background-color: #fff;
    border: 1px solid #F93449;
    color: #F93449;
}

.btn-cd-outline-primary:disabled {
    pointer-events: none;
}

.swal2-container {
    .swal2-popup {
        width: nb-theme(swal-width) !important;
        padding: 0 0 34px;
    }

    &.toastr-error {
        width: nb-theme(swal-toastr-width) !important;
        padding: 4em 0 0;

        &.swal2-top-right {
            top: 50px !important;
            right: 50px !important;
        }

        .swal2-toast,
        .swal2-popup {
            width: nb-theme(swal-toastr-width) !important;
            min-height: nb-theme(swal-toastr-height) !important;
            background-color: var(--bg-error);
            border: 1px solid var(--border-error);
            border-radius: 5px;
            padding: 0 20px !important;

            @include media-breakpoint-down(lg) {
                max-width: 68%;
                position: fixed;
                left: 125px;
                top: 110px;
                padding: 0 28px !important;
            }

            @include media-breakpoint-down(sm) {
                max-width: 92%;
                top: 65px;
                left: 12px;
                padding: 0 25px !important;
                min-height: 73px;
            }

            .icon {
                &:before {
                    position: absolute;
                    left: 14.86px;
                    top: 11px;
                    content: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM13.59 5L10 8.59L6.41 5L5 6.41L8.59 10L5 13.59L6.41 15L10 11.41L13.59 15L15 13.59L11.41 10L15 6.41L13.59 5Z' fill='%23E25967'/%3E%3C/svg%3E%0A");
                }
            }

            button.swal2-close {
                position: absolute;
                top: 14.86px;
                right: -13.78px;
                line-height: 16px;
                height: 16px;
                width: 16px;
                font-size: 16px;

                &:before {
                    content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.85521 5.58574L11.8052 0.635742L13.2192 2.04974L8.26921 6.99974L13.2192 11.9497L11.8052 13.3637L6.85521 8.41374L1.90521 13.3637L0.491211 11.9497L5.44121 6.99974L0.491211 2.04974L1.90521 0.635742L6.85521 5.58574Z' fill='%230D3C61'/%3E%3C/svg%3E");

                }

                @include media-breakpoint-down(lg) {
                    left: -4px;
                }

                @include media-breakpoint-down(sm) {
                    right: -14.86px;
                    top: 14.86px;
                }
            }

            .message {
                padding-left: 10px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--text-primary);

                @include media-breakpoint-down(sm) {
                    font-size: 14px;
                    line-height: 18px;
                }
            }

            .swal2-timer-progress-bar {
                background: 1px solid var(--border-error)
            }

        }
    }
}

.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite
}

.loader::before,
.loader::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #E2E4E6;
    animation: prixClipFix 2s linear infinite;
}

.loader::after {
    inset: 8px;
    transform: rotate3d(90, 90, 0, 180deg);
    border-color: #D92B45;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

@keyframes prixClipFix {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
    }

    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
    }

    75%,
    100% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
    }
}

.mat-mdc-progress-spinner.white-spinner {
    --mdc-circular-progress-active-indicator-color: white;
}