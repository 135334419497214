@import '@angular/material/theming';
@include mat-core();
//http://mcg.mbitson.com/#!?mcgpalette0=%23f93449&themename=mcgtheme
$md-customercare: (
    50: #fee7e9,
    100:#fdc2c8,
    200:#fc9aa4,
    300:#fb7180,
    400:#fa5264,
    500:#f93449,
    600:#f82f42,
    700:#f72739,
    800:#f62131,
    900:#f51521,
    A100:#ffffff,
    A200:#fff1f1,
    A400:#ffbec0,
    A700:#ffa4a8,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

:root {
  --mdc-theme-error: #F93449;
  --mdc-checkbox-state-layer-size: 1.125rem;
}

//boton
.mat-mdc-button-base {
  height: 40px !important;
}

.mat-mdc-raised-button {
 width: 100%;
 height: 40px;
 margin: 0 auto !important;
 padding: 0.375rem 0.75rem;
 font-family: 'Archivo', sans-serif;
 font-style: normal;
 font-weight: 500;
 font-size: 14px;
 line-height: 28px !important;
 text-align: center;
 letter-spacing: 0.002em;
 color: #fff !important;
 box-shadow: none !important;
}

.mat-form-field {
  margin: -10% 0;
}

//input
.mdc-text-field {
  font-family: 'Archivo', sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16.5px !important;
  letter-spacing: 0.002em !important;

  height: 40px !important;
  border-radius: 5px !important;
  padding: 0 12px !important;
}

.mdc-text-field--filled {
  align-items: center !important;
}

.mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-90%) scale(0.61) !important;
}

.tpsa-button {
  width: 50%;
  border-radius: 30px;
}

.mdc-text-field__input {
  padding-top: 2px !important;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: #3F3F40 !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background: #F9F9F9 !important;
  border-top: 1px solid #E2E4E6 !important;
  border-left: 1px solid #E2E4E6 !important;
  border-right: 1px solid #E2E4E6 !important;
  border-radius: 5px !important;
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay, 
.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}

//Botón disabled
.mat-mdc-raised-button[disabled][disabled]{
  background-color: #FED8D7;
}

// select
.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0px !important;
}

.mat-mdc-option {
  min-height: 36px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  border-bottom: 1px solid #E2E4E6;

  font-family: 'Archivo', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.002em;
}

.mat-mdc-option .mat-pseudo-checkbox {
  display: none;
}

// error
.mat-mdc-form-field-subscript-wrapper {
  height: 0 !important;
}

.mat-mdc-form-field-subscript-wrapper:has(.mat-mdc-form-field-error-wrapper) {
  height: 14px !important;
}

.mat-mdc-form-field-error-wrapper {
  top: 2px !important;
  padding: 0 12px !important;
}

.mat-mdc-form-field-error {
  font-family: 'Archivo', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  letter-spacing: 0.002em;
  color: #D92B45 !important;
}

.mat-mdc-form-field-bottom-align::before {
  height: 0px !important;
}

//dialog
.mat-mdc-dialog-content {
  padding: 0 !important;
  max-height: 100% !important;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.1);
}

// checkbox

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  border-color: #E2E4E6 !important;
  background-color: #FFFFFF !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background {
  border-color: #D92B45 !important;
  background-color: #F93449 !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #FFFFFF !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: #FFFFFF !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  border-color: #E2E4E6 !important;
  background-color: #A6A4A4 !important;
}

.mat-mdc-checkbox-touch-target {
  width: 18px !important;
  height: 18px !important;
}

.mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #E2E4E6 !important;
}

.mat-mdc-checkbox-ripple, .mdc-checkbox__ripple {
  border-radius: 2px !important;
}

//FED8D7
/*Defino que el color primario del app será el código 500 de la paleta md-customercare*/
$my-app-primary: mat-palette($md-customercare, 500);
$my-app-accent: mat-palette($md-customercare, A200, A100, A400);

$my-app-warn: mat-palette($mat-deep-orange);
$my-app-error: mat-palette($mat-red, A700, A100, A400);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);

@include angular-material-theme($my-app-theme);

$primary: mat-color($my-app-primary);
$accent: mat-color($my-app-accent);
$warn: mat-color($my-app-warn);
$error: mat-color($my-app-error);

